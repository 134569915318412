<template>
  <v-card v-if="AnyShoppingCartItem" elevation="5" class="mobile-shopping-cart">
    <div class="mobile-shopping-cart-content" @click="onClick">
      <div>
        <v-icon left color="#FFFFFF" class="foodcard-cart-icon"
          >mdi-shopping-outline</v-icon
        >
        <span>{{ ShoppingCartItemCount }}</span>
      </div>
      <div>
        <span style="font-weight: bold">{{ buttonText }}</span>
      </div>
      <div>
        <span>{{ ShoppingCartTotalPriceInKr }}</span>
        <span style="margin-left: 10px">kr</span>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "MobileShoppingCart",
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    ShoppingCartTotalPriceInKr() {
      const totalPrice = this.$store.getters.cart.reduce(
        (prev, cur) => prev + cur.totalPrice * cur.nrOfItems,
        0
      );
      return totalPrice / 100;
    },
    ShoppingCartItemCount() {
      let nrOfItems = 0;
      this.$store.getters.cart.forEach((item) => {
        nrOfItems += item.nrOfItems;
      });
      return nrOfItems;
    },
    AnyShoppingCartItem() {
      const items = this.$store.getters.cart;
      if (items && items.length > 0) return true;

      return false;
    },
  },
  methods: {},
};
</script>
