import Moment from "moment";
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import TimeAndDateSelector from "@/views/checkout/TimeAndDate/TimeAndDateSelector/TimeAndDateSelector.vue";
import { EatingMode } from "@/types/enums/eatingMode";
import { getEatingModeDto } from "@/types/dto/eateries/eateryDtoFunctions";
import ErrorHelper from "@/helpers/errorHelpers";
import ErrorMsgBox from "@/components/common/ErrorMsgBox/ErrorMsgBox.vue";
import EateryInfoModal from "@/components/eatery/EateryInfoModal.vue";
import {
  dateAndTimeAreWithinHoursForCurrentEatingMode,
  getFirstAvailableOpeningTimeForCurrentEatingMode,
} from "@/helpers/currentEateryHelper";

const codebehind = {
  components: {
    EateryInfoModal,
    PersonalInformationForm,
    FoodyButton,
    TimeAndDateSelector,
    ErrorComponent,
    ErrorHelper,
    ErrorMsgBox,
  },
  data() {
    const defaultForm = Object.freeze({
      name: "",
      phone: "",
      email: "",
      request: "",
      numberOfPeople: 2,
    });
    return {
      date: Moment().format("YYYY-MM-DD"),
      time: "",
      form: Object.assign({}, defaultForm),
      defaultForm,
      dialog: false,
      personalInformationFormValid: false,
      validTime: false,
      invalidReasons: [] as string[],
      eatingMode: EatingMode.DineIn,
      showError: false,
      showEateryInfoModal: false,
    };
  },
  created() {
    this.setUpFieldsFromLoggedInUser();
    this.setUpCurrentEatingMode();
    this.setUpDateAndTime();
  },
  computed: {
    currentEateryName() {
      return this.currentEatery.displayName;
    },
    getLoggedInUser() {
      return this.$store.getters.loggedInUser;
    },
    getInvalidReasonString() {
      return this.invalidReasons.join("\n");
    },

    validTimeInput: {
      get() {
        return this.validTime;
      },
      set(val: string) {
        this.validTime = val;
      },
    },
    invalidReasonsInput: {
      set(val: string) {
        this.invalidReasons = val;
      },
      get() {
        return this.invalidReasons;
      },
    },
    setDate: {
      get() {
        return this.date;
      },
      set(val: string) {
        this.date = val;
      },
    },
    setTime: {
      get() {
        return this.time;
      },
      set(val: string) {
        this.time = val;
      },
    },
    validateNumberOfPeople() {
      if (this.form.numberOfPeople > 0 && this.form.numberOfPeople != null)
        return true;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    currentEatingMode() {
      return EatingMode.DineIn;
    },
    formIsValid() {
      return (
        this.personalInformationFormValid &&
        this.date &&
        this.validTime &&
        this.validateNumberOfPeople
      );
    },
    formIsEmpty() {
      let hasNewValue = false;
      for (const propertyName in this.form) {
        const val = this.form[propertyName];

        if (val !== "" && val !== 1 && val !== "false") {
          hasNewValue = true;
        }
      }
      return !hasNewValue;
    },
    orderReadyEstimateMinutes() {
      const currentEatery = this.$store.getters.eateryInfo;
      const eatingModeDto = getEatingModeDto(
        currentEatery,
        this.currentEatingMode
      );
      return eatingModeDto.orderReadyEstimateMinutes ?? 0;
    },
  },
  watch: {
    //If user signs in after registration form is shown.
    getLoggedInUser: function () {
      if (this.getLoggedInUser) {
        this.form.name = this.getLoggedInUser.name;
        this.form.phone = this.getLoggedInUser.phone;
        this.form.email = this.getLoggedInUser.email;
      }
    },
    date() {
      const timeIsValid = dateAndTimeAreWithinHoursForCurrentEatingMode(
        this.date,
        this.time
      );
      if (!timeIsValid) {
        const date = new Date(this.date);
        const nextOpeningTime =
          getFirstAvailableOpeningTimeForCurrentEatingMode(date);
        this.date = Moment(nextOpeningTime).format("YYYY-MM-DD");
        this.time = Moment(nextOpeningTime).format("HH:mm");
      }
    },
  },
  methods: {
    submit: async function submit() {
      let tableReservationObject = null;
      const nanoTime = this.time + ":00.000000";
      const resDateTime = this.date + "T" + nanoTime;
      tableReservationObject = {
        fullName: this.form.name,
        Phone: this.form.phone,
        Email: this.form.email,
        Request: this.form.request,
        NrOfPeople: this.form.numberOfPeople,
        EateryId: parseInt(this.currentEatery.eateryId),
        ReservationDateTime: resDateTime,
      };
      const response = await this.$store.dispatch(
        "sendTableReservation",
        tableReservationObject
      );
      if (ErrorHelper.responseSuccess(response)) {
        this.$router.push(
          "/" + this.$store.getters.eateryName + "/bordreservasjon"
        );
      } else {
        this.showError = true;
      }
    },

    setUpDateAndTime() {
      const openingDateTime =
        getFirstAvailableOpeningTimeForCurrentEatingMode();

      if (!openingDateTime) {
        throw new Error(
          "Unable to retrieve first available opening time for current eating mode"
        );
      }

      this.date = Moment(openingDateTime).format("yyyy-MM-DD");
      this.time = Moment(openingDateTime).format("HH:mm");
    },
    setUpFieldsFromLoggedInUser() {
      if (this.getLoggedInUser) {
        this.form.name = this.getLoggedInUser.name;
        this.form.phone = this.getLoggedInUser.phone;
        this.form.email = this.getLoggedInUser.email;
      }
    },
    setUpCurrentEatingMode() {
      this.$store.dispatch("setEatingMode", EatingMode.DineIn);
    },
  },
};
export default codebehind;
