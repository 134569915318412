<template>
  <div style="width: 100%">
    <div class="options-head">
      <h3 v-if="options.length > 0">Velg</h3>
    </div>
    <v-radio-group
      v-model="active"
      style="height: auto"
      hide-details="auto"
      @change="(option) => updateSelectedVariant(option)"
    >
      <div class="food-item-variants">
        <div
          v-for="option in options"
          :key="option.id"
          class="food-item-variant"
        >
          <v-radio class="shrink foody-radio-flex" dense :value="option">
            <template slot="label">
              <div class="foody-radio-flex">
                <span>{{ option.name }}</span>
                <span v-if="option.price" class="food-item-variant-price"
                  >{{ +option.price / 100 }} kr</span
                >
              </div>
            </template>
          </v-radio>
        </div>
      </div>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  name: "Menuitemvariant",
  props: {
    options: Array,
  },
  data() {
    const selectedOption =
      this.options.find((o) => o.isDefault === true) ?? this.options[0];
    return {
      active: selectedOption,
    };
  },
  methods: {
    updateSelectedVariant(option) {
      this.options.forEach((x) => (x.selected = false));
      option.selected = true;
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls.v-input {
  height: 10px;
}
</style>
