import DeliveryDto from "@/types/dto/eateries/deliveryDto";
import EateryDto from "@/types/dto/eateries/eateryDto";
import TakeawayDto from "@/types/dto/eateries/takeawayDto";
import { EatingMode } from "@/types/enums";
import {
  getFirstAvailableOpeningTimeForDate,
  timeIsValidForDate,
  getValidOpeningDateRangesForDate,
  getLastPossibleReservationTimeForDate,
} from "./eateryOpeningTimeValidator";
import Moment from "moment";
import store from "@/store/index";

export function getCurrentEatery() {
  const eatery = store.getters.eateryInfo;
  if (!eatery) return null;

  return eatery as EateryDto;
}

// Our system uses 7 as Sunday, but JS uses 0 as Sunday. Use this function to get the correct day.
export function getDay(date: Date) {
  return date.getDay() === 0 ? 7 : date.getDay();
}

export function getCurrentEatingMode() {
  const eatingMode = store.getters.selectedEatingMode;
  if (!eatingMode) return null;

  return eatingMode as EatingMode;
}

function getCurrentEatingModeDto() {
  const eatery = getCurrentEatery();
  if (!eatery) return null;

  switch (getCurrentEatingMode()) {
    case EatingMode.DineIn:
      return eatery.dineIn;
    case EatingMode.Delivery:
      return eatery.delivery;
    case EatingMode.TakeAway:
      return eatery.takeAway;
    default:
      return null;
  }
}

export function validateDateTime(
  date: string,
  time: string,
  reservation: boolean
): string[] {
  const validationMessages: string[] = [];

  if (!dateAndTimeAreWithinHoursForCurrentEatingMode(date, time)) {
    validationMessages.push("Tiden som er satt er utenfor åpningstid!");
    return validationMessages;
  }

  if (reservation) {
    const tooCloseResult = reservationTooCloseToClosingTime(time, date);
    if (tooCloseResult.tooClose) {
      validationMessages.push(
        `Reservasjonen er for tett opptil stengetid kl. ${tooCloseResult.closingTime}, vennligst reserver minst 45 minutter før stengetid`
      );
    }
  }

  return validationMessages;
}

function getPausedUntilUtcDateForCurrentEatingModeDto(): Date | null {
  const eatingModeDto = getCurrentEatingModeDto();
  if (!eatingModeDto) return null;

  const eatingMode = getCurrentEatingMode();
  if (!eatingMode) return null;

  if (eatingMode == EatingMode.TakeAway) {
    return (eatingModeDto as TakeawayDto).pausedUntilUtc ?? null;
  }

  if (eatingMode == EatingMode.Delivery) {
    return (eatingModeDto as DeliveryDto).pausedUntilUtc ?? null;
  }
  return null;
}

export function currentEatingModeIsOpenNow() {
  const eatingModeDto = getCurrentEatingModeDto();
  if (!eatingModeDto) return false;

  const pausedUntilDate = getPausedUntilUtcDateForCurrentEatingModeDto();

  const orderReadyEstimateMinutes =
    eatingModeDto.orderReadyEstimateMinutes ?? 0;
  const openingHours = eatingModeDto.openingHours;

  const firstAvailableOpeningDate = getFirstAvailableOpeningTimeForDate(
    new Date(),
    openingHours,
    orderReadyEstimateMinutes,
    pausedUntilDate
  );

  const nowPlusBuffer = Moment()
    .add(orderReadyEstimateMinutes, "minutes")
    .toDate();

  return firstAvailableOpeningDate <= nowPlusBuffer;
}

export function dateAndTimeAreWithinHoursForCurrentEatingMode(
  dateString: string,
  timeString: string
) {
  const eatingModeDto = getCurrentEatingModeDto();
  if (!eatingModeDto) return false;

  const openingHours = eatingModeDto.openingHours;
  const orderReadyEstimateMinutes =
    eatingModeDto.orderReadyEstimateMinutes ?? 0;
  const pausedUntilDate = getPausedUntilUtcDateForCurrentEatingModeDto();

  return timeIsValidForDate(
    timeString,
    dateString,
    openingHours,
    orderReadyEstimateMinutes,
    pausedUntilDate
  );
}

export function reservationTooCloseToClosingTime(
  timeString: string,
  dateString: string
): { tooClose: boolean; closingTime: string } {
  const eatingModeDto = getCurrentEatingModeDto();
  if (!eatingModeDto) return { tooClose: false, closingTime: "" };
  const dateOfOrder = new Date(dateString);
  const orderMoment = Moment(dateString + " " + timeString);
  const openingHours = eatingModeDto.openingHours.find(
    (day) => day.dayId === getDay(dateOfOrder)
  );

  const lastPossibleOrderingTime = getLastPossibleReservationTimeForDate(
    new Date(dateString),
    openingHours,
    45
  );

  const tooClose = orderMoment.isAfter(lastPossibleOrderingTime);
  if (tooClose) {
    const tooCloseTime = openingHours.closing.split(":");
    return {
      tooClose: true,
      closingTime: `${tooCloseTime[0]}:${tooCloseTime[1]}`,
    };
  } else {
    return { tooClose: false, closingTime: "" };
  }
}

export function getFirstAvailableOpeningTimeForCurrentEatingMode(date?: Date) {
  const eatingModeDto = getCurrentEatingModeDto();
  if (!eatingModeDto) return null;

  const openingHours = eatingModeDto.openingHours;
  const orderReadyEstimateMinutes =
    eatingModeDto.orderReadyEstimateMinutes ?? 0;
  const pausedUntilDate = getPausedUntilUtcDateForCurrentEatingModeDto();

  return getFirstAvailableOpeningTimeForDate(
    date ?? new Date(),
    openingHours,
    orderReadyEstimateMinutes,
    pausedUntilDate
  );
}

export function currentEatingModeHasValidOpeningHoursOnDate(
  date: Date
): boolean {
  const eatingModeDto = getCurrentEatingModeDto();
  if (!eatingModeDto) return false;

  const openingHours = eatingModeDto.openingHours;
  const orderReadyEstimateMinutes =
    eatingModeDto.orderReadyEstimateMinutes ?? 0;
  const pausedUntilDate = getPausedUntilUtcDateForCurrentEatingModeDto();

  return (
    getValidOpeningDateRangesForDate(
      date,
      openingHours,
      orderReadyEstimateMinutes,
      pausedUntilDate
    ).length > 0
  );
}
