<template>
  <div class="cart-item">
    <div
      class="foody-flex-row foody-flex-align-center foody-flex-center foody-space-betweeen"
    >
      <h3 :class="dineInOnlyItemColor(item)">
        {{ item.name }}
        <v-btn
          v-if="item.allowCustomerMessage"
          class="mx-1"
          fab
          x-small
          :color="getColorState"
          style="height: 30px; width: 30px"
          @click.stop="showCartItemMessageModal = true"
        >
          <v-icon v-if="!item.CustomerMessage" medium color="white">
            mdi-chat-processing-outline
          </v-icon>
          <v-icon v-if="item.CustomerMessage" medium color="white">
            mdi-check-bold
          </v-icon>
        </v-btn>
      </h3>
      <div :class="`${dineInOnlyItemColor(item)} cart-item-sum`">
        {{ (item.totalPrice * item.nrOfItems) / 100 }} kr
      </div>
    </div>
    <div class="foody-flex-row foody-space-betweeen align-items-center">
      <div>
        <template v-if="hasVariant">
          <span class="shopping-cart-option-text">{{ item.variant.name }}</span>
        </template>
        <template v-if="hasChoices">
          <div v-for="(c, index) in choices" :key="index" class="cart-options">
            <span class="shopping-cart-option-text"
              >{{ c.name }} {{ c.price ? "(+" + (c.price / 100) + "kr)" : "" }}</span
            >
          </div>
        </template>
      </div>
      <div>
        <!-- kr: {{ item.totalPrice * item.nrOfItems }} -->
        <ShoppingCartStepper
          style="align-items: center; height: 100%; margin-right: -7px"
          :on-click-minus="minus"
          :on-click-plus="plus"
          :counter="item.nrOfItems"
          :can-add-more="canAddMore"
        />
      </div>
    </div>
    <CartItemMessageModal v-model="showCartItemMessageModal" :item="item" />
  </div>
</template>
<script>
import ShoppingCartStepper from "@/components/shopping-cart/ShoppingCartStepper.vue";
import { getItemCountInCart, itemOutOfStock } from "@/helpers/cartHelper.ts";
import CartItemMessageModal from "@/components/menues/menu-items/CartItemMessageModal.vue";
// this function map {} to []
function values(obj) {
  return Object.keys(obj).map((key) => obj[key]);
}

export default {
  components: { ShoppingCartStepper, CartItemMessageModal },
  props: {
    removeItem: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canAddMore: true,
      showCartItemMessageModal: false,
    };
  },
  computed: {
    getColorState() {
      return this.item.CustomerMessage ? "#757575" : "#9F0073";
    },
    choices() {
      return values(this.item.choices);
    },
    hasChoices() {
      return this.choices.length > 0;
    },
    hasVariant() {
      return this.item.variant;
    },
    CurrentEatingMode() {
      return this.$store.getters.selectedEatingMode ?? 2;
    },
    TableObject() {
      return this.$store.getters.tableObject;
    },
    itemCountInCart() {
      return getItemCountInCart(this.item);
    },
  },
  watch: {
    itemCountInCart() {
      this.updateCanAddMore();
    },
  },
  mounted() {
    this.updateCanAddMore();
  },
  methods: {
    dineInOnlyItemColor(item) {
      //If current eating mode is 'Take Away / Delivery', and Cart har some 'Dine in Only' items then 'Checkout' button is disabled and a message is shown.
      if (!this.TableObject && this.CurrentEatingMode !== 1 && item.dineInOnly)
        return "shopping-cart-item-invalid";
      return "";
    },
    async updateCanAddMore() {
      const menu = await this.$store.getters.menu;
      if (!menu) return;
      const allMenuItems = menu.groupedMenuItems.reduce(
        (concatted, group) => concatted.concat(group.menuItems),
        []
      );
      const menuItem = allMenuItems.find(
        (menuItem) => menuItem.menuItemId == this.item.menuItemId
      );
      this.canAddMore = !itemOutOfStock(menuItem);
    },
    minus() {
      const item = this.item;
      if (item.nrOfItems <= 1) this.removeItem();
      else {
        this.$store.dispatch("decrementCartItem", item);
      }
      this.updateCanAddMore();
    },
    plus() {
      const item = this.item;
      this.$store.dispatch("incrementCartItem", item);
      this.updateCanAddMore();
    },
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}
.cart-item-sum {
  white-space: nowrap;
  padding-left: 15px;
}
.shopping-cart-item-invalid {
  color: red;
}
h3 {
  margin-left: 0;
  margin-right: 0;
}
.cart__item {
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  text-align: left;
  padding: 2px;
}

.cart__item:hover {
  background-color: #efefef;
}

.cart__item-label {
  flex-grow: 1;
}

.cart__item-label {
  font-size: 80%;
  display: block;
  line-height: 1em;
}

.cart__item-action {
  margin: 0.3rem;
}
</style>
