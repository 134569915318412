<template>
  <FoodyCard :parentclasses="['card-view']">
    <v-card-text>
      <!-- Title -->
      <v-card-title style="font-weight: bold">
        Terms and Conditions
      </v-card-title>
      <!-- Title -->
      <v-card-title class="text-underline" style="line-height: 2.5rem">
        Kjøpsbetingelser
      </v-card-title>
      <v-card-subtitle class="terms-subtitle"> Betaling </v-card-subtitle>
      <v-card-text>
        Samtlige priser i nettbutikken er oppgitt i kr inkl. mva. Vi bruker
        DIBS Easy som samarbeidsparter på betaling. Du som kunde kan enkelt
        velge mellom å betale med et betalingskort eller VIPPS.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Betaling med Visa / Mastercard
      </v-card-subtitle>
      <v-card-text>
        Når du betaler med kort reserveres beløpet på ditt betalingskort når du
        bekrefter din bestilling og trekkes når spisestedet godkjenner
        bestillingen. Ved kortbetaling benytter vi oss av 3D Secure som er en
        sikkerhetsstandard laget av VISA og Mastercard for betalinger over
        internett. Overføringen skjer med sikker SSL-kryptering. Det innebærer
        at ingen uvedkommende kan se dine kortopplysninger når den sendes fra
        nettbutikken til betalingsleverandøren.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Betaling med VIPPS
      </v-card-subtitle>
      <v-card-text>
        Når du betaler med Vipps vil beløpet bli reservert på det spesifiserte
        kortet og kontoen du har registrert i Vipps appen når du bekrefter en
        bestilling. Beløpet rekkes når spisesteder godkjenner bestillingen. Ved
        første gangs bruk av VIPPS kreves registrering med BankID. Dermed
        fungerer betaling via Vipps sikkert og følger de samme
        sikkerhetsstandarder laget av VISA og MasterCard for betalinger over
        internett. Hos oss er det 100% trygt å handle.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Angrerett </v-card-subtitle>
      <v-card-text>
        Angreretten gjelder ikke ved kjøp av ferskvarer som mat og blomster selv
        om disse er bestilt via nettløsninger.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Reklamasjon </v-card-subtitle>
      <v-card-text>
        Hvis det er en feil med det du får levert vil vi henvise til spisestedet
        du bestilte fra og deres vilkår for reklamasjon. Feil i leveranse eller
        avvik i kvalitet på leveransen må meldes spisestedet raskest mulig, og
        du fremlegger kvittering og/ eller ordrenummer for bestillingen.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Tilbakebetaling
      </v-card-subtitle>
      <v-card-text>
        Ved reklamasjon vil spisestedet gjennom getFOOD-løsningen refundere
        gjeldende beløp. Ved kortbetaling vil pengene overføres til
        betalingskortet du benyttet da du la inn bestillingen. Transaksjonen
        skjer gjennom Easy, og du kan forvente at det tar mellom 4-10 dager før
        pengene er tilbake på ditt betalingskort.
      </v-card-text>
      <!-- Title-->
      <v-card-title class="text-underline" style="line-height: 2.5rem">
        Frakt & Levering
      </v-card-title>
      <v-card-subtitle class="terms-subtitle"> Dekning </v-card-subtitle>
      <v-card-text>
        Ikke alle spisesteder tilbyr hjemlevering. Dette vil være tydelig
        beskrevet på spisestedets getFOOD.no-profil.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Leveringsmetoder
      </v-card-subtitle>
      <v-card-text>
        Vi henviser til spisestedenes egne leveringstjenester eller tredjeparts
        transportør og deres vilkår for levering.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Hente selv </v-card-subtitle>
      <v-card-text>
        Du kan selv hente din bestilling på spisestedet. Når du bestiller
        take-away og velger «Hent selv» vil du motta en hentekode på e-post
        eller til din getFOOD.no-profil som du oppgir i kassen når du henter
        bestillingen. Ved bestilling vil du få et estimat på når bestillingen
        kan forventes å være klar til henting. Ved bestilling av take-away
        plikter spisestedet å levere bestillingen på en måte som er egnet for
        transport.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Leveringstid </v-card-subtitle>
      <v-card-text>
        Leveringen tar normalt mellom 30-60 minutter fra bestilling. I perioder
        med stor pågang kan leveringstiden være noe lengre. getFOOD har funksjon
        for at restaurantene kan oppgi forventet leveringstid basert på trafikk,
        og denne skal være synlig på spisestedets profil på getFOOD.no. Du er
        selv ansvarlig for å oppgi riktig e-post og telefonnummer ved
        bestilling.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Jeg savner ordrebekreftelse
      </v-card-subtitle>
      <v-card-text>
        Hvis du ikke har mottatt ordrebekreftelse på mail eller til din
        getFOOD-profil innen kort tid etter du har bekreftet din bestilling, kan
        det ha oppstått en feil med ditt kjøp, og vi ber deg ta kontakt med
        kundeservice. Vær oppmerksom på at mail kan havne i spam-filteret, det
        kan dermed være lurt å sjekke søppelposten.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Hva koster frakt?
      </v-card-subtitle>
      <v-card-text>
        Prisen på hjemlevering varierer. De ulike spisestedene setter egne
        satser, og disse vil også variere med avstand fra spisested og andre
        variabler. Pris på hjemlevering vil fremkomme i handlekurven ved
        bestilling.
      </v-card-text>
      <!-- Title -->
      <v-card-title class="text-underline" style="line-height: 2.5rem">
        Min Bestilling
      </v-card-title>
      <v-card-subtitle class="terms-subtitle">
        Har min ordre gått igjennom? </v-card-subtitle
      ><v-card-text>
        Du får en ordrebekreftelse med et ordrenummer på e-post eller til din
        getFOOD-profil så fort kjøpet ditt har blitt godkjent av spisestedet. På
        ordrebekreftelsen får du et estimat på når bestillingen forventes å være
        klar.
      </v-card-text>

      <v-card-subtitle
        class="terms-subtitle"
        style="font-size: 1rem; font-style: italic"
      >
        Er min bestilling ferdig?
      </v-card-subtitle>
      <v-card-text />
      <v-card-subtitle class="terms-subtitle">
        På spisestedet:
      </v-card-subtitle>
      <v-card-text>
        Hvis spisestedet har bordservering får du maten servert når den er klar.
        Hvis spisestedet leverer maten via pick-up point vil du få et varsel når
        maten er klar for henting.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Take-away - Hent selv:
      </v-card-subtitle>
      <v-card-text>
        Så fort bestillingen din er ferdig, sender vi en kvittering på e-post
        eller til din getFOOD.no-profil som bekrefter at bestillingen kan
        hentes.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Take-away – hjemlevering:
      </v-card-subtitle>
      <v-card-text>
        Så fort bestillingen er ferdig, sender vi en kvittering på e-post eller
        til din getFOOD.no-profil om at bestillingen er på vei hjem til deg.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Kan jeg endre eller kansellere min bestilling?
      </v-card-subtitle>
      <v-card-text>
        Du kan endre eller kansellere din ordre frem til den har blitt
        verifisert av spisestedet. Ut over dette er det opp til det enkelte
        spisested å vurdere om de kan endre en bestilling etter at den er
        verifisert.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Jeg vil opprette en konto, men får feilmelding?
      </v-card-subtitle>
      <v-card-text>
        Ta kontakt med vår kundeservice på e-post, kontaktskjema eller telefon.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Jeg har glemt passordet mitt?
      </v-card-subtitle>
      <v-card-text>
        Klikk på «Logg inn», deretter «Glemt passord». Fyll ut e-posten du har
        registrert deg med på getFood.no, så sender vi deg en lenke som du
        følger for å endre passord.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Hvordan bruker jeg «mine favoritter»?
      </v-card-subtitle>
      <v-card-text>
        I «Mine favoritter» kan du lagre spisesteder og bestillinger for raskt å
        finne disse igjen. Klikk på ikonet på spisestedets profil, eller «Lagre
        som favoritt» på en gjennomført ordre. For å finne frem til dine
        favoritter, logg inn først og klikk på «Mine favoritter» som ligger
        øverst på siden til enhver tid.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Vi tar følgende forbehold
      </v-card-subtitle>
      <v-card-text>
        <ul>
          <li>Varen kan være utsolgt.</li>
          <li>Farger kan avvike fra virkeligheten.</li>
          <li>Skrivefeil.</li>
          <li>Avvik kan forekomme.</li>
        </ul>
      </v-card-text>

      <v-card-title class="text-underline" style="font-weight: bold">
        Personvern og Cookies
      </v-card-title>
      <v-card-text>
        Nedenfor går vi gjennom vår personvernserklæring og opplyser om hvilke
        personopplysninger som behandles når du bruker vår tjeneste. Aksepterer
        du personvernserklæringen samtykker du til getFOODs innsamling, bruk og
        behandling av dine personopplysninger og vilkårene det medfører. Les
        derfor nøye gjennom innholdet i personvernserklæringen.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Ansvarlig for databehandlingen
      </v-card-subtitle>
      <v-card-text>
        <ul>
          <li>Foody AS</li>
          <li>Torggata 11</li>
          <li>0181 Oslo</li>
        </ul>
        <v-card-text>
          Du kan til enhver tid kontakte oss på følgende kanaler for
          henvendelser knyttet til getFOODs innsamling eller bruk av dine
          personopplysninger:
        </v-card-text>
        <ul>
          <li>Telefon: 9520 3432 (09-16)</li>
          <li>E-post: hello@foody.no</li>
        </ul>
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Dine rettigheter
      </v-card-subtitle>
      <v-card-subtitle style="font-size: 1rem">
        Retten til innsyn:
      </v-card-subtitle>
      <v-card-text>
        En av de viktigste rettighetene du har, er retten til å be om tilgang
        til de opplysningene vi har registrert på deg. I dette tilfellet vil vi
        gi deg en kopi av dine personopplysninger.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Retten til å bli glemt:
      </v-card-subtitle>
      <v-card-text>
        En annen viktig rettighet du har i relasjonen til oss er retten til å
        bli glemt, hvilket betyr at du kan sende en forespørsel og be om at vi
        sletter de opplysningene vi har registrert på deg. Vi sletter ikke
        nødvendigvis alle opplysningene ettersom vi er pålagt å fortsette å
        lagre visse data for å kunne overholde de juridiske kravene.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Retten til innsigelse:
      </v-card-subtitle>
      <v-card-text>
        Du har rett til å motsette deg behandling av dine personopplysninger av
        enkelte årsaker. Du kan for eksempel motsette seg behandlingen av dine
        persondata for direkte markedsføring, inkludert profilering.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Retten til korrigering:
      </v-card-subtitle>
      <v-card-text>
        Hvis du mener at opplysningene vi har registrert på deg er unøyaktige
        eller ufullstendige, så gi oss beskjed, og vi vil sørge for å oppdatere
        informasjonen din.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Retten til begrensning
      </v-card-subtitle>
      <v-card-text>
        I kombinasjon med noen av dine andre rettigheter kan du også be om at vi
        begrenser bruk av dine personopplysninger, f.eks. i stedet for full
        sletting eller under vår vurdering av dine innsigelser.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Retten til dataportabilitet
      </v-card-subtitle>
      <v-card-text>
        Du kan sende en forespørsel og be oss om å gi deg de personopplysningene
        du har gitt oss i et strukturert, vanlig og maskinlesbart format og å
        overføre opplysningene direkte til en bestemt mottaker.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem"> Klager </v-card-subtitle>
      <v-card-text>
        Hvis du ønsker å sende inn en klage for hvordan vi håndterer dine
        personopplysninger, kan du alltid kontakte kundeservice. Du kan også
        sende inn en klage til din lokale data tilsynsmyndighet.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Hvordan og hvilke opplysninger vi behandler
      </v-card-subtitle>
      <v-card-text>
        For å behandle enkelte bestillinger trenger vi opplysninger fra deg. I
        forbindelse ved oppretting av en konto hos getfOOD, og annen bruk av
        våre tjenester behandler vi typisk følgene opplysninger om deg dersom
        det blir gitt samtykke:
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Brukerkontoopplysninger
      </v-card-subtitle>
      <v-card-text>
        Brukerkontoopplysninger innebærer opplysninger vi trenger for å kunne
        opprette en brukerkonto hos getFOOD for deg. Disse inneholder ditt
        fornavn, etternavn, e-postadresse, mobilnummer, passord, adresse, poster
        og poststed. Du kan endre disse opplysningene på profilsiden.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Kjøpshistorikk </v-card-subtitle>
      <v-card-text>
        Kjøpshistorikk innebærer informasjon om bestillinger du har gjort
        gjennom oss slik at du skal kunne få oversikt over dette.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Kundeservice </v-card-subtitle>
      <v-card-text>
        Tar du kontakt med vår kundeservice vil vi ha tilgang til denne
        dialogen.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Tekniske og analytiske opplysninger
      </v-card-subtitle>
      <v-card-text>
        Vi lagrer tekniske opplysninger om din enhet og tilkobling til våre
        sider, samt dine valg og interaksjoner på nettsiden. Disse opplysningene
        genereres ved ditt bruk av vår nettside av første og tredjeparts
        cookies.
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Betalingskonto </v-card-subtitle>
      <v-card-text>
        For å kunne utføre refusjoner og andre tilbakebetalinger har vi tilgang
        til kontonummer og transaksjonsnummer knyttet til en bestilling gjennom
        vår betalingspartner.
      </v-card-text>
      <v-card-text>Les mer om NETS sine vilkår under: </v-card-text>
      <v-card-text>
        <a href="https://www.nets.eu/GDPR/Pages/Privacy-notice-for-nets.aspx "
          >https://www.nets.eu/GDPR/Pages/Privacy-notice-for-nets.aspx
        </a>
      </v-card-text>
      <v-card-text>
        Når du fullfører en bestilling, håndteres betalingen av vår
        betalingsløsningspartner Nets, som mottar din betalingsinformasjon. Når
        vi mottar din bestilling deler vi ditt navn, adresse, e-post og telefon
        med spisestedet som skal tilberede bestillingen og eventuelt deres
        transportpartnere for å fullføre leveransen.
      </v-card-text>
      <v-card-text
        >Våre betalings- og bestillingspartnere er forpliktet til å oppbevare
        opplysningene dine trygt.</v-card-text
      >
      <v-card-subtitle style="font-size: 1rem">
        Forbedring av din brukeropplevelse
      </v-card-subtitle>
      <v-card-text>
        Vi arbeider kontinuerlig for å gi deg den aller beste bruker- og
        handelsopplevelsen på våre nettsider. Dette gjør vi på ulike vis, men en
        viktig del av det er å kunne spore dine søk på nettstedet vårt. Dette
        gjør vi ved hjelp av informasjonskapsler. Les mer om dette under Cookies
        lenger ned.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem"> Markedsføring </v-card-subtitle>
      <v-card-text>
        Vi bruker også dine opplysninger for å kunne sende markedsføring til deg
        og for å kunne skreddersy hvilken markedsføring du ser. Du kan alltid
        motsette deg behandlingen av dine personopplysninger for direkte
        markedsføringsformål ved å gå til ”Min Side” og redigere dine
        preferanser og ved å melde deg av nyhetsbrev i linken som følger
        e-posten.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Lovmessige krav
      </v-card-subtitle>
      <v-card-text>
        I tillegg til ovennevnte behandling av dine personopplysninger, bruker
        og lagrer vi dine personlige data for å overholde regelverkskrav, f.eks.
        bokføringsregler. Rettslig grunnlag for innsamling og bruk av dine
        personopplysninger Vi samler inn og bruker dine personopplysninger på
        følgende juridiske grunnlag:
      </v-card-text>
      <v-card-text>
        <ol>
          <li>Utføring av en bestilling</li>
          <li>
            Vår legitime interesse
            <ul>
              <li>Kundeservice</li>
              <li>Forbedring av din brukeropplevelse</li>
            </ul>
          </li>
          <li>
            Ditt samtykke
            <ul>
              <li>Min konto</li>
              <li>Informasjonskapsel-basert markedsføring</li>
            </ul>
          </li>
          <li>
            Våre juridiske forpliktelser
            <ul>
              <li>Lovmessige krav</li>
            </ul>
          </li>
        </ol>
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Tidsrom for lagring av personopplysninger
      </v-card-subtitle>
      <v-card-text>
        Vi lagrer bare dine personopplysninger så lenge det er nødvendig, for å
        oppfylle formålet de ble samlet inn for eller overholde
        regelverkskravene, f.eks. bokføringsregler. Når du er logget inn på din
        personlige kundekonto, kan du alltid redigere informasjonen din. Du kan
        også sende inn en forespørsel der du ber oss om å slette spesifikk
        informasjon eller all personlig informasjon vi har om deg. Vi vil
        overholde forespørselen med mindre vi er forpliktet til å lagre
        informasjonen av lovmessige årsaker eller for å etablere, utøve eller
        forsvare en rettslig påstand.
      </v-card-text>
      <v-card-subtitle style="font-size: 1rem">
        Dine rettigheter
      </v-card-subtitle>
      <v-card-text
        >Som forbruker i EU er dine personopplysninger omfattet av EUs
        forordning for personvern (GDPR). Dette betyr at dine rettigheter som
        den registrerte er de samme i alle EU-land. Hvis du ønsker å utøve noen
        av dine rettigheter, bes du sende e-post til hello@foody.no
      </v-card-text>
      <v-card-subtitle class="terms-subtitle"> Cookies </v-card-subtitle>
      <v-card-subtitle style="font-size: 1rem">
        Google Analytics
      </v-card-subtitle>
      <v-card-text>Vi bruker Google Analytics til å:</v-card-text>
      <v-card-text>
        <ul>
          <li>Identifisere unike brukere.</li>
          <li>Huske antall og tidspunkt for tidligere besøk.</li>
          <li>Finne ut hvor brukere kommer fra.</li>
          <li>Bestemme start og slutt på et besøk.</li>
        </ul>
      </v-card-text>
      <v-card-subtitle class="terms-subtitle">
        Informasjonskapsler fra kommersielle tredjepartsaktører
      </v-card-subtitle>
      <v-card-text
        >Vi bruker informasjonskapsler fra tredjepartsaktører (Adform, Google
        Adwords og Facebook) for å kunne gi våre brukere/kunder relevant
        markedsføring og en god brukeropplevelse. Vi bruker det også til å måle
        effekten av og vise mer relevante annonser. Informasjonskapslene
        registrerer ingen personlige opplysninger på nettstedet og dataene er
        anonymisert. Informasjonskapslene gjør det mulig å:
      </v-card-text>
      <v-card-text>
        <ul>
          <li>Frekvensstyre annonser på bakgrunn av aktivitet.</li>
          <li>
            Se brukeradferd på nettstedet i forhold til den direkte annonsering
            som skjer.
          </li>
          <li>
            Samle informasjon om produktinteresse for å presentere mer relevante
            annonser.
          </li>
          <li>Målrette annonser til utvalgte kundegrupper.</li>
          <li>
            <a
              href="https://site.adform.com/privacy-center/platform-privacy/opt-out/"
            >
              Les mer om Adforms personvern på adform.com.
            </a>
          </li>
          <li>
            <a href="https://policies.google.com/technologies/ads?hl=no">
              Les mer om Google Adwords personvern på google.com.</a
            >
          </li>
          <li>
            <a href="https://www.facebook.com/privacy/explanation">
              Les mer om Facebooks personvern på facebook.com.
            </a>
          </li>
        </ul>
      </v-card-text>
      <v-card-text>
        I Google Analytics har vi også aktivert støtte for bannerannonsering med
        remarketing. Dette gjør vi for å kunne hjelpe til med å servere annonser
        som er mer relevante for dine interesser. Videre medfører dette at
        tredjepartsleverandører, inkludert Google, kan vise våre annonser nettet
        over. Støtten for bannerannonsering bruker informasjonskapsler fra
        Google Analytics og DoubleClick for å informere, optimalisere og vise
        annonser basert på dine tidligere besøk på vårt nettsted.
      </v-card-text>
      <v-card-text>
        Nøyaktig hvilke cookies som brukes og varigheten på disse vil variere ut
        ifra din nettleserhistorikk, og vil derfor ikke bli beskrevet i detalj.
        Felles for de cookies som brukes på nettstedet som ikke allerede er
        beskrevet i tabellen under, brukes med det formål om å servere deg mer
        relevante annonser.
      </v-card-text>
      <v-card-text>
        Hvis du surfer nettet som innlogget bruker på Google, så vil Google
        prøve å danne seg et bilde av hvem du er og hva du interesserer deg for.
        Ønsker du å se denne profilen, endre den, eller slette data i profilen
        så kan du besøke<a href="https://adssettings.google.com/authenticated">
          annonseinnstillingene til Google.</a
        >
      </v-card-text>
      <v-card-text
        >Vi anbefaler deg også å besøke
        <a href="https://tools.google.com/dlpage/gaoptout/"
          >Google Analytics’ nåværende tilgjengelige bortvalgsalternativer.</a
        ></v-card-text
      >
      <v-card-text
        >Våre kundedata oppbevares etter norsk lov, og vil ikke bli delt
        med/solgt til en tredjepart. Foody AS er ansvarlig for
        kredittkorttransaksjoner gjort gjennom denne nettsiden. Tekst og bilder
        tilhører Foody AS. Vi ber om at tekst og bilder ikke kopieres uten
        tillatelse. Ved repost på Instagram/Facebook skal bildene tydelig merkes
        med #repost @getFOOD_no.
      </v-card-text>
    </v-card-text>
  </FoodyCard>
</template>
<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
export default {
  components: {
    FoodyCard,
  },
};
</script>
