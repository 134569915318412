<template>
  <div class="widget-container" @keyup.enter="submit">
    <div v-if="checkoutStage === 2 || !isMobile">
      <NavBar
        HeaderText="Bestillingsdetaljer"
        :callBack="goShoppingCart"
        class="d-md-none d-lg-none"
      ></NavBar>
      <div style="margin-bottom: 1rem" class="foody-checkout-foodycard">
        <CustomerInfoMessage />
      </div>

      <FoodyCard class="foody-checkout-foodycard">
        <div
          v-if="!loggedInUser"
          style="text-align: center; margin-bottom: 15px"
        >
          <p>Har du profil?</p>
          <FoodyButton :btn-color="'primary'" :on-click="showLoginSheet">
            Logg inn
          </FoodyButton>
          <div class="border-line"></div>
        </div>
        <v-container fluid>
          <p
            v-if="disableTimePicker()"
            class="header-time-and-date"
            style="text-align: center"
          >
            Når vil du ha maten?
          </p>
          <template cols="12" sm="12">
            <!-- <TimeRadioSelector
              :selectedTime="time"
              :currentEatery="currentEatery"
              :currentEatingMode="currentEatingMode"
              :validateDateAndtime="validateDateAndtime"
              :timeOption="timeOption"
              @selectedTime="time = $event"
              @timeOption="timeOption = $event"
            >
            </TimeRadioSelector> -->
            <!-- v-if="timeOption === '2'" -->
            <template v-if="disableTimePicker()">
              <TimeAndDateSelector
                :date="date"
                :time="time"
                :currentEatingMode="currentEatingMode"
                :reservation="false"
                :validTime="validTime"
                :openingTimeBufferInMinutes="
                  eatingModeDto.orderReadyEstimateMinutes || 0
                "
                @time="setTime = $event"
                @date="setDate = $event"
                @validTime="validTimeInput = $event"
              >
              </TimeAndDateSelector>
            </template>
            <ErrorMsgBox
              v-if="!dateAndTimeValid"
              :error="{
                message: 'Velg et gyldig klokkeslett.',
                type: 'info',
              }"
              :selectedDate="date"
              :selectedTime="time"
            />
            <v-textarea
              v-if="currentEatingMode == 3"
              v-model="deliverynote"
              name="input-7-1"
              label="Leveringsnotat"
              hint="Hvis du ønsker å gi en beskjed til de leverer maten skriv her"
            />
          </template>
          <UserInformation
            v-if="!TableObject"
            ref="userInformationRef"
            v-disable-tab-on-class="['.mdi-close', '.mdi-eye-off']"
            style="padding: 15px"
            :userInformationObject="userInformation"
            :personalInformationFormValid="personalInformationFormValid"
            :addressInformationFormValid="addressInformationFormValid"
            :selectedEatingMode="currentEatingMode"
            :nameRequired="eatingModeDto.requireName"
            :emailRequired="eatingModeDto.requireEmail"
            :phoneRequired="eatingModeDto.requirePhone"
            @userInformationObject="userInformation = $event"
            @personalInformationFormValid="
              personalInformationFormValid = $event
            "
            @addressInformationFormValid="addressInformationFormValid = $event"
          >
          </UserInformation>
          <template v-else-if="TableObject && !TableObject.TableId">
            <v-text-field
              v-if="!getTableNr()"
              v-model="tableId"
              color="blue darken-2"
              label="Bordnummer"
              placeholder="skriv inn ditt bordnummer"
            />
          </template>
          <foody-button
            :btn-color="'primary'"
            :on-click="submit"
            :parentclasses="['shopping-cart-button']"
            :disabled="
              !everythingIsValid || DineInOnlyItemInCart || isQrAndClosed
            "
          >
            TIL BETALING
          </foody-button>
        </v-container>
      </FoodyCard>
    </div>
    <div v-if="checkoutStage == 1 && isMobile" style="width: 100%">
      <NavBar
        HeaderText="Handlekurv"
        :callBack="goBack"
        class="d-md-none d-lg-none"
      ></NavBar>
      <ShoppingCartContainer
        v-if="isMobile"
        :on-click="TableObject ? submit : navToOrderConfirmation"
        :button-text="TableObject ? 'TIL BETALING' : 'NESTE'"
        :button-disabled="
          !shoppingCartIsValid || DineInOnlyItemInCart || isQrAndClosed
        "
        :prev-route="prevRoute"
        :errorMsg="OrderErrorMsg"
        :checkoutStage="checkoutStage"
        :selectedDate="date"
        :selectedTime="time"
      />
    </div>
    <PaymentDialog
      ref="PaymentDialog"
      :userInformationObject="userInformation"
      :deliverynote="deliverynote"
      :tableId="tableId"
      :selectedTime="time"
      :selectedDate="date"
    ></PaymentDialog>
    <LoginBottomSheet
      :showSheet="showSheet"
      @sheetUpdated="(val) => (showSheet = val)"
    />
  </div>
</template>
<script>
import Vue from "vue";
import codeBehind from "./Checkout.ts";
export default Vue.extend({
  name: "Checkout",
  mixins: [codeBehind],
});
</script>
<style scoped>
.header-time-and-date {
  text-align: center;
  margin-bottom: 0;
}
.border-line {
  width: auto;
  border-bottom: 4px solid;
  border-color: #f5f8fa;
  margin: 10px -16px 10px -16px;
}
</style>
