<template>
  <div class="scrollable">
    <FoodyCard :parentclasses="['food-item']" style="padding: 0 !important">
      <div :id="item.menuItemId" style="display: block; width: 100%">
        <div class="foody-flex-row" style="padding: 8px">
          <div style="flex: 1; margin-right: 5px">
            <Header :item="item" />
            <DescriptionSection :item="item" :menuViewMode="menuViewMode" />
          </div>
          <div
            v-if="item.blobUrlPhoto && item.blobUrlPhoto !== ''"
            class="foodcard-right"
          >
            <div style="overflow: hidden; display: flex; height: 115px">
              <v-img
                :src="item.blobUrlPhoto"
                contain
                style="justify-content: center"
              ></v-img>
            </div>
          </div>
        </div>
        <div style="margin: 0 8px">
          <div v-if="showVariantsInCard" class="foody-flex-row sheet-props">
            <MenuItemVariant
              :key="optionsAndVariantsKey"
              :options="item.menuItemVariants"
              style="margin-bottom: 15px"
            ></MenuItemVariant>
          </div>
          <div v-if="showFoodPropsInCard" class="foody-flex-row">
            <div style="width: 100%">
              <FoodItemProperties
                :key="optionsAndVariantsKey"
                class="foodcard-properties-box"
                :food-properties="item.foodProperties"
              ></FoodItemProperties>
            </div>
          </div>
        </div>
        <div
          class="foody-flex-row foody-flex-center foody-space-between"
          style="padding: 8px; border-top: 2px solid #f0f0f0"
        >
          <div style="flex: 1; align-content: left; padding-top: 5px">
            <span class="food-item-price">
              {{ price / 100 }}
              kr
            </span>
          </div>
          <div class="foodcard-right">
            <PrimaryButton
              :item="item"
              :hasOnlyQrAndDineIn="hasOnlyQrAndDineIn"
              :addItemToCart="addToCart"
              :showMenuItemBottomSheet="showMenuItemBottomSheet"
              :tableObjectIsTruthy="!!TableObject"
              :menuViewMode="menuViewMode"
              :menuItemVariantCount="getVariantCount()"
              :menuItemPropertiesCount="getPropsCount()"
              :menuItemPropertiesOptionsCount="itemFoodPropertyOptionsCount"
            />
          </div>
        </div>
      </div>
    </FoodyCard>
    <MenuItemBottomSheet
      :showSheet="showSheet"
      :activeMenuItem="item"
      :hasOnlyQrAndDineIn="hasOnlyQrAndDineIn"
      @sheetUpdated="(val) => (showSheet = val)"
    />
  </div>
</template>
<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
import MenuItemBottomSheet from "@/components/menues/menu-items/FoodItem/MenuItemBottomSheet.vue";
import FoodItemProperties from "@/components/menues/menu-items/menu-item-properties/FoodItemProperties.vue";
import MenuItemVariant from "@/components/menues/menu-items/menu-item-properties/MenuItemVariant.vue";
import { MenuViewMode } from "@/types/enums/MenuViewMode";
import { getItemCountInCart, itemOutOfStock } from "@/helpers/cartHelper.ts";
import Header from "./Header.vue";
import DescriptionSection from "./DescriptionSection.vue";
import PrimaryButton from "./PrimaryButton.vue";
import {
  calculatePrice,
  getFoodPropertyOptionsCount,
  resetFoodPropertyOptions,
  resetMenuItemVariants,
} from "./utils";

export default {
  components: {
    FoodyCard,
    MenuItemBottomSheet,
    MenuItemVariant,
    FoodItemProperties,
    Header,
    DescriptionSection,
    PrimaryButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    menuViewMode: {
      type: Number,
      required: true,
      default: MenuViewMode.Compressed,
    },
    hasOnlyQrAndDineIn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showSheet: false,
      optionsAndVariantsKey: 1,
    };
  },
  computed: {
    TableObject() {
      return this.$store.getters.tableObject;
    },
    optionPrice() {
      const selectedFoodProps = this.getSelectedFoodProperties(this.item);

      const selectedOptions =
        this.getSelectedFoodPropertiesOptions(selectedFoodProps);
      const sum = this.getOptionsSum(selectedOptions);
      return sum;
    },
    price() {
      const selectedFoodProps = this.getSelectedFoodProperties(this.item);
      const selectedOptions =
        this.getSelectedFoodPropertiesOptions(selectedFoodProps);

      return calculatePrice(
        this.item,
        selectedOptions,
        this.getSelectedFoodVariant(this.item)
      );
    },
    itemOutOfStock() {
      const outOfStock = itemOutOfStock(this.item);
      return outOfStock;
    },
    showVariantsInCard() {
      if (this.menuViewMode === MenuViewMode.Full) return true;
      if (this.menuViewMode === MenuViewMode.MaxCompact) return false;
      const variantCount = this.getVariantCount();
      const propsCount = this.getPropsCount();
      const hasVariants = variantCount;
      const hasProps = propsCount;

      if (this.menuViewMode === MenuViewMode.Compressed) {
        if (hasVariants && hasProps) {
          return false;
        }
        if (variantCount > 3) {
          return false;
        }
      }
      return variantCount ? true : false;
    },
    showFoodPropsInCard() {
      if (this.menuViewMode === MenuViewMode.Full) return true;
      if (this.menuViewMode === MenuViewMode.MaxCompact) return false;
      const variantCount = this.getVariantCount();
      const propsCount = this.getPropsCount();
      const hasManyPropsOption =
        this.getPropsCount() === 1 &&
        getFoodPropertyOptionsCount(this.item) <= 3;

      if (this.menuViewMode === MenuViewMode.Compressed) {
        if (hasManyPropsOption && variantCount === 0) {
          return true;
        } else {
          return false;
        }
      }
      return propsCount === 1 && variantCount === 0 ? true : false;
    },
    itemFoodPropertyOptionsCount() {
      return getFoodPropertyOptionsCount(this.item);
    },
  },
  methods: {
    getVariantCount() {
      return this.item.menuItemVariants ? this.item.menuItemVariants.length : 0;
    },
    getPropsCount() {
      return this.item.foodProperties ? this.item.foodProperties.length : 0;
    },
    showMenuItemBottomSheet() {
      this.showSheet = true;
    },
    getSelectedFoodVariant(item) {
      if (!item.menuItemVariants || item.menuItemVariants.length === 0)
        return null;
      let selectedItem = item.menuItemVariants.find((x) => x.selected);
      if (selectedItem === undefined) {
        selectedItem = item.menuItemVariants.find((x) => x.isDefault);
        selectedItem.selected = true;
      }
      return selectedItem;
    },
    getSelectedFoodProperties(item) {
      return item.foodProperties.filter((x) =>
        x.foodPropertyOptions.some(
          (foodPropertyOption) =>
            foodPropertyOption && foodPropertyOption.selected
        )
      );
    },
    getSelectedFoodPropertiesOptions(foodProperties) {
      if (foodProperties && foodProperties.length > 0) {
        const res = foodProperties.map((x) => {
          const options = x.foodPropertyOptions.filter((option) => {
            if (option.selected) {
              return option;
            }
          });
          return options;
        });
        return res.flat();
      } else return [];
    },
    getOptionsSum(options) {
      let sum = 0;
      for (let index = 0; index < options.length; index++) {
        const option = options[index];
        sum += option.price;
      }
      return sum;
    },
    addToCart() {
      const itemCountInCart = getItemCountInCart(this.item);
      if (this.item.stock && itemCountInCart >= this.item.stock) return;

      this.$store.dispatch("addCartItem", this.item);
      this.$store.dispatch("setSnackbarText", `Lagt i handlekurv`);

      resetFoodPropertyOptions(this.item);
      resetMenuItemVariants(this.item);
      this.optionsAndVariantsKey++;
    },
  },
};
</script>

<style scoped>
.scrollable {
  --webkit-transform: translate3d(0, 0, 0);
}
</style>
