<template>
  <div>
    <div class="options-head">
      <h3>Velg {{ foodProp.name }}</h3>
    </div>
    <div>
      <v-radio-group
        v-model="active"
        hide-details="auto"
        style="height: auto"
        @change="(event) => updateSelectedOption(event)"
      >
        <div
          v-for="option in foodProp.foodPropertyOptions"
          :key="option.id"
          class="foody-radio-flex-group"
        >
          <v-radio
            class="shrink ma-0 pa-0 foody-radio-flex"
            dense
            :value="option"
          >
            <template slot="label">
              <span>{{ option.name }}</span>
              <span v-if="option.price" class="foody-options-price"
                >+ {{ +option.price / 100 }} kr</span
              >
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "Foodoptionradio",
  props: {
    foodProp: Object,
  },
  data() {
    const selectedOption =
      this.foodProp.foodPropertyOptions.find((o) => o.selected === true) ??
      this.foodProp.foodPropertyOptions[0];
    selectedOption.selected = true;
    return {
      active: selectedOption,
    };
  },
  methods: {
    updateSelectedOption(option) {
      this.foodProp.foodPropertyOptions.forEach((x) => (x.selected = false));
      option.selected = true;
      this.$emit("selectedOptUpdated", option);
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls.v-input {
  height: 10px;
}
</style>
